import React, { useState } from "react";
import "./css/DashboardHome.css";
import { Link } from "react-router-dom";
import axios from "axios";

export default function StateMaster() {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(true);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [state, setState] = useState("");
    const [states, setStates] = useState([]);
    const [editModeState, setEditModeState] = useState(false);
    const [editingState, setEditingState] = useState({});
    const [showStates, setShowStates] = useState(false);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/sign-in";
        return null;
    }

    const handleUpdateState = async (id, newState) => {
        try {
            const response = await axios.put(`https://jubilant-backend.netlysystems.com/updateStateMaster/${id}`, { state: newState });

            if (response.data.error) {
                alert(response.data.error);
            } else {
                alert("State Master and related States Successfully Updated");
                fetchStates();
                setEditModeState(false);
                setEditingState({});
            }
        } catch (error) {
            console.error('Error updating state:', error);
            alert('Failed to update state');
        }
    };
    const handleStateEditClick = (state) => {
        setEditModeState(true);
        setEditingState(state);
        setState(state.state);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };
    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };
    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "./sign-in";
    };

    const fetchStates = async () => {
        try {
            const response = await fetch("https://jubilant-backend.netlysystems.com/states");
            const data = await response.json();
            if (data.error) {
                console.error(data.error);
            } else {
                setStates(data);
                setShowStates(true);
            }
        } catch (error) {
            console.error("Error fetching States:", error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("https://jubilant-backend.netlysystems.com/addState", {
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                state,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert("State Successfully Added")
                    window.location.reload()
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-server icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-users icon"></i>Machine Master</Link></li>
                            <li>
                                <a href="/stateMaster" className="active">
                                    <i className="fas fa-globe icon"></i> State Master
                                </a>
                            </li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <>
                <section id="content">
                    <nav>
                        <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                    </nav>

                    <main>
                        <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
                        <ul className="breadcrumbs">
                            <li><Link to="/">Home</Link></li>
                            <li className="divider">/</li>
                            <li><Link to="#" className="active">State Master</Link></li>
                        </ul>

                        <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white", marginBottom: "30px" }}>
                            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add State</h1>
                            <div style={{ marginBottom: '1rem', width: '100%' }}>
                                <label>State Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter State Name"
                                    style={{ width: '100%' }}
                                    onChange={(e) => setState(e.target.value)} required
                                />
                            </div>

                            <div style={{ width: '100%' }}>
                                <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                                    Add State
                                </button>
                            </div>
                        </form>

                        <div>
                            <button onClick={fetchStates} className="btn btn-primary mb-2">View All States</button>
                        </div>

                        {/* State */}

                        <div>
                            {editModeState && (
                                <div>
                                    <input
                                        type="text"
                                        value={editingState.state}
                                        onChange={(e) => setEditingState({ ...editingState, state: e.target.value })}
                                    />
                                    <button onClick={() => handleUpdateState(editingState._id, editingState.state)}>Save</button>
                                </div>
                            )}
                        </div>
                        {showStates && (
                            <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                        <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>State</th>
                                        <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {states.map((state, index) => (
                                        <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                            <td style={{ width: "80px", border: "1px solid black" }}>{index + 1}</td>
                                            <td style={{ width: "200px", border: "1px solid black" }}>{state.state}</td>
                                            <td style={{ width: "60px", border: "1px solid black" }}>
                                                <i className="fas fa-pencil-square" onClick={() => handleStateEditClick(state)}></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </main>
                </section>
            </>
            {/* NAVBAR */}
        </>
    );
}