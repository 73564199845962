import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';

const AdminClientHome = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const usersPerPage = 30; // Show only 30 users per page

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const fetchData = useCallback((page = 1, searchTerm = '') => {
        fetch(`https://jubilant-backend.netlysystems.com/getAllClient?page=${page}&limit=${usersPerPage}&searchTerm=${searchTerm}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "Ok") {
                    setData(data.data);
                    setCurrentPage(data.currentPage);
                    setTotalPages(data.totalPages);
                } else {
                    console.error("Error fetching data:", data.error);
                }
            })
            .catch((error) => {
                console.error("Error fetching client:", error);
            });
    }, []);

    useEffect(() => {
        fetchData(currentPage, searchTerm); // Fetch data when page or searchTerm changes
    }, [fetchData, currentPage, searchTerm]);  // Add searchTerm as dependency

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    // Change page handler
    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber); // Update page number and fetch new data
        }
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/adminUser'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li>
                        <Link to="/adminClient" className="active">
                            <i className="fas fa-users icon"></i> Clients
                        </Link>
                    </li>
                    <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/adminRequestApprovals'}><i className="fas fa-thumbs-up icon"></i>Approvals</Link></li>
                    <li><Link to={'/adminRequest'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/adminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/adminTransferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/admin-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/admin-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/adminClient">Site</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Site :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by name, email, phone or gst.no..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {data.length === 0 && <p>Site doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Email</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Address</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Gst No.</th>
                                    <th style={{ width: "130px", backgroundColor: "#007bff", color: "white" }}>Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((user, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.fname}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.email}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.address}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.gstno}</td>
                                        <td style={{ width: "130px", border: "1px solid black" }}>{user.phone}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages || data.length < usersPerPage}
                            >
                                Next Page
                            </button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    )
}

export default AdminClientHome;