import React, { useState } from "react";
import "./css/DashboardHome.css";
import { Link } from "react-router-dom";
import axios from "axios";

export default function InventoryMaster() {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(true);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [productName, setProductName] = useState("");
    const [products, setProducts] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editingProduct, setEditingProduct] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 30;
    const [showAllProducts, setShowAllProducts] = useState(false);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/sign-in";
        return null;
    }

    const handleUpdateProduct = async (id, newProduct) => {
        try {
            const response = await axios.put(`https://jubilant-backend.netlysystems.com/updateInventoryMaster/${id}`, { productName: newProduct });

            if (response.data.error) {
                alert(response.data.error);
            } else {
                alert("Product Master and related Products Successfully Updated");
                setEditMode(false);
                setEditingProduct({});
                setProductName("");
                fetchProductNames();
            }
        } catch (error) {
            console.error('Error updating product:', error);
            alert('Failed to update product');
        }
    };

    const handleEditClick = (product) => {
        setEditMode(true);
        setEditingProduct(product);
        setProductName(product.productName);
    };

    const handleShowAllProducts = () => {
        setShowAllProducts((prev) => !prev);
        if (!showAllProducts) {
            fetchProductNames();
        }
    };

    const fetchProductNames = () => {
        fetch("https://jubilant-backend.netlysystems.com/productNames")
            .then((res) => res.json())            
            .then((data) => {
                if (data.error) {
                    console.error(data.error);
                } else {
                    setProducts(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching product names:", error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("https://jubilant-backend.netlysystems.com/addProductName", {
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                productName,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert("Product Successfully Added");
                    fetchProductNames();
                    setProductName("");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "./sign-in";
    };

    // Filter products based on the search term
    const filteredProducts = products.filter(product =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li>
                                <a href="/inventoryMaster" className="active">
                                    <i className="fas fa-store icon"></i> Inventory Master
                                </a>
                            </li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Inventory Master</Link></li>
                    </ul>
                    <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                        <h1 style={{ color: "white", margin: 2 }}>Inventory :</h1>
                        <input
                            className="search-input"
                            type="text"
                            placeholder="Search by Product Name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: "white", marginTop: "20px" }}>
                        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Product Name</h1>
                        <div style={{ marginBottom: '1rem', width: '100%' }}>
                            <label>Product Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                style={{ width: '100%' }}
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)} required
                            />
                        </div>

                        <div style={{ width: '100%' }}>
                            <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                                Add Product Name
                            </button>
                        </div>
                    </form>
                    <button onClick={handleShowAllProducts}>
                        {showAllProducts ? "Hide All Products" : "View All Products"}
                    </button>
                    <div>
                        {editMode && (
                            <div>
                                <input
                                    type="text"
                                    value={editingProduct.productName}
                                    onChange={(e) => setEditingProduct({ ...editingProduct, productName: e.target.value })}
                                />
                                <button onClick={() => handleUpdateProduct(editingProduct._id, editingProduct.productName)}>Save</button>
                            </div>
                        )}
                    </div>
                    {showAllProducts && (
                        <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "400px", backgroundColor: "#007bff", color: "white" }}>Product Name</th>
                                    <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((product, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{indexOfFirstItem + index + 1}</td>
                                        <td style={{ width: "400px", border: "1px solid black" }}>{product.productName}</td>
                                        <td style={{ width: "60px", border: "1px solid black" }}>
                                            <i className="fas fa-pencil-square" onClick={() => handleEditClick(product)}></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    {/* Pagination Controls */}
                    <div className="pagination">
                        <button
                            className="btn btn-secondary"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span style={{ color: "red", margin: "10px" }} className="page-info">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            className="btn btn-secondary"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
}