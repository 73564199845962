import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddMachineForm from './AddMachineForm';
import UpdateMachine from './UpdateMachine';
import './css/DashboardHome.css';

const StaffMachine = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const usersPerPage = 30; // Show only 30 users per page

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const fetchData = useCallback((page = 1, searchTerm = '') => {
        fetch(`https://jubilant-backend.netlysystems.com/getAllMachine?page=${page}&limit=${usersPerPage}&searchTerm=${searchTerm}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "Ok") {
                    setData(data.data);
                    setCurrentPage(data.currentPage);
                    setTotalPages(data.totalPages);
                } else {
                    console.error("Error fetching data:", data.error);
                }
            })
            .catch((error) => {
                console.error("Error fetching machine:", error);
            });
    }, []);
    useEffect(() => {
        fetchData(currentPage, searchTerm);
    }, [fetchData, currentPage, searchTerm]);

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "./sign-in";
    };

    // Function to set the user to be edited
    const handleEditClient = (user) => {
        setEditMachine(user);
    };

    // State to track the user to be edited
    const [editMachine, setEditMachine] = useState(null);

    // State to manage the visibility of the Add User form
    const [showAddUserForm, setShowAddUserForm] = useState(false);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/sign-in";
        return null;
    }

    // Function to update the user data
    const updateMachine = (userId, updatedMachineData) => {
        fetch("https://jubilant-backend.netlysystems.com/updateMachine", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                userId: userId,
                updatedMachineData: updatedMachineData,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.message === "Machine updated successfully") {
                    alert("Machine updated successfully");
                    setEditMachine(null);
                    fetchData();
                } else {
                    alert("Failed to update machine. Please try again.");
                }
            })
            .catch((error) => {
                console.error("Error updating machine:", error);
                alert("Error updating machine. Please try again.");
            });
    };

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    // Change page handler
    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber); // Update page number and fetch new data
        }
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to="/"><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/staffClient'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li>
                        <Link to="/staffMachine" className="active">
                            <i className="fas fa-server icon"></i> Machines
                        </Link>
                    </li>
                    <li><Link to={'/staffTransferMachine'}><i className="fas fa-truck icon"></i>Transfer Machines</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/staff-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/staff-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Staff,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/staffMachine">Machines</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Machines :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by Machine brand, type, category, site, battery-type, capacity, state, year, sno, owned by, invoice, e-way, challan No., loan.no, or supervisor..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button style={{ marginBottom: '10px', borderRadius: '10px' }} className="add-user-btn" onClick={() => setShowAddUserForm(true)}>
                            <i className="fas fa-plus-circle"></i> Add Machine
                        </button>
                        {showAddUserForm && <AddMachineForm />}
                        {editMachine && <UpdateMachine machine={editMachine} updateMachine={updateMachine} />}
                        {data.length === 0 && <p>Machine doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Brand Name</th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Machine Category</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Machine Type</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Battery Type</th>
                                    <th style={{ width: "220px", backgroundColor: "#007bff", color: "white" }}>Machine Capacity <span style={{ color: "black", fontWeight: "bolder" }}>(TON)*</span></th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Site Name</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>State</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Supervisor Name</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Serial No.</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Challan No.</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>E-Way Bill</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Year of Purchase</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Owned By</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Purchase Invoice</th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Purchase Amount</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Purchase Date</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Loan Number</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Loan Amount</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Total No. of Emi</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Emi Amount</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Emi Start</th>
                                    <th style={{ width: "150px", backgroundColor: "#007bff", color: "white" }}>Emi End</th>
                                    <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((user, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.brand}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.type}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{user.category}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{user.battery}</td>
                                        <td style={{ width: "220px", border: "1px solid black" }}>{user.weight}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.clientName}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.state}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.supervisor}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.sno}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.challanNo}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.ewaybill}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.yearofpurchase}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.ownedby}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.purchaseInvoice}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.purchaseAmount}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{formatDate(user.purchaseDate)}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.loanNo}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.loanAmount}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.totalEmi}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{user.emiAmount}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{formatDate(user.emiStart)}</td>
                                        <td style={{ width: "150px", border: "1px solid black" }}>{formatDate(user.emiEnd)}</td>
                                        <td style={{ width: "60px", border: "1px solid black" }}><i onClick={() => handleEditClient(user)} className="fas fa-pencil-square"></i></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => setCurrentPage(1)}
                                disabled={currentPage === 1}
                            >
                                First Page
                            </button>
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages || data.length < usersPerPage}
                            >
                                Next Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => setCurrentPage(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                Last Page
                            </button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    )
}

export default StaffMachine