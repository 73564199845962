import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import TransferMachineForm from './TransferMachineForm';

const SuperAdminTransferMachineDashboard = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const [searchDate, setSearchDate] = useState('');
    const [showTransferMachineForm, setShowTransferMachineForm] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const usersPerPage = 30;

    const fetchInspections = useCallback((page = 1, searchTerm = '', searchDate = '') => {
        fetch(`https://jubilant-backend.netlysystems.com/getAllTransferMachine?page=${page}&limit=${usersPerPage}&searchTerm=${searchTerm}&searchDate=${searchDate}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "Ok") {
                    setData(data.data);
                    setCurrentPage(data.currentPage);
                    setTotalPages(data.totalPages);
                } else {
                    console.error("Error fetching data:", data.error);
                }
            })
            .catch((error) => {
                console.error("Error fetching inspection:", error);
            });
    }, []);
    useEffect(() => {
        fetchInspections(currentPage, searchTerm, searchDate);
    }, [fetchInspections, currentPage, searchTerm, searchDate]);

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "./sign-in";
    };

    if (!isLoggedIn) {
        alert("Token Expired. Please login to see the content.");
        window.location.href = "/sign-in";
        return null;
    }

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    // Function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };

    // Change page handler
    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li><Link to={'/userdashboard'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li>
                        <a href="/transferMachine" className="active">
                            <i className="fas fa-truck icon"></i> Transfer
                        </a>
                    </li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/transferMachine">Transfer Machine</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>

                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Machines :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by Machine type, challan.no, category, old site, new site, state, transporter name, e-way bill or concern person..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <br />
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0", width: "65%", margin: "auto", borderRadius: "5px", }} className="search-bar-container">
                            <h3 style={{ color: "white", margin: "2px 10px 2px 0", fontSize: "20px", fontWeight: "bolder" }}>Search by Date:</h3>
                            <input
                                type="date"
                                placeholder="Search by Date"
                                value={searchDate}
                                onChange={(e) => setSearchDate(e.target.value)}
                                style={{ outline: "none", border: "none", background: "white", color: "black", fontSize: "14px", padding: "8px", fontWeight: "bold", borderRadius: "5px" }}
                            />
                        </div>
                        <button style={{ marginBottom: '10px', borderRadius: '10px' }} className="add-user-btn" onClick={() => setShowTransferMachineForm(true)}>
                            <i className="fas fa-plus-circle"></i> Transfer Machine
                        </button>
                        {showTransferMachineForm && <TransferMachineForm />}
                        {data.length === 0 && <p>Machine doesn't exist</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "125px", backgroundColor: "#007bff", color: "white" }}>Date</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Challan.No</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>E-Way Bill</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Machine S.No</th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Machine Category</th>
                                    <th style={{ width: "140px", backgroundColor: "#007bff", color: "white" }}>Machine Type</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Battery Type</th>
                                    <th style={{ width: "220px", backgroundColor: "#007bff", color: "white" }}>Machine Capacity <span style={{ color: "black", fontWeight: "bolder" }}>(TON)*</span></th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Dispatched From</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Dispatched To</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Old State</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>New State</th>
                                    <th style={{ width: "160px", backgroundColor: "#007bff", color: "white" }}>Transporter Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((user, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                        <td style={{ width: "125px", border: "1px solid black" }}>{formatDate(user.date)}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.challanNo}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.ewaybill}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.sno}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.type}</td>
                                        <td style={{ width: "140px", border: "1px solid black" }}>{user.category}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.battery}</td>
                                        <td style={{ width: "220px", border: "1px solid black" }}>{user.weight}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.oldClient}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.newClientName}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.oldState}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.state}</td>
                                        <td style={{ width: "160px", border: "1px solid black" }}>{user.transporterName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => setCurrentPage(1)}
                                disabled={currentPage === 1}
                            >
                                First Page
                            </button>
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next Page
                            </button>
                            <button
                                className="btn btn-primary mx-2"
                                onClick={() => setCurrentPage(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                Last Page
                            </button>
                        </div>
                    </div>
                </main>
            </section>
            {/* NAVBAR */}
        </>
    );
}

export default SuperAdminTransferMachineDashboard;