import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './css/DashboardHome.css';
import UpdateUser from './updateUser';
import AddUserForm from './AddUserForm';

const UserDashboard = () => {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMasterOpen, setIsMasterOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const toggleMaster = () => {
        setIsMasterOpen(!isMasterOpen);
    };
    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "./sign-in";
    };

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const usersPerPage = 30; // Show only 30 users per page

    const fetchData = useCallback((page = 1, searchTerm = '') => {
        fetch(`https://jubilant-backend.netlysystems.com/getAllUser?page=${page}&limit=${usersPerPage}&searchTerm=${searchTerm}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "Ok") {
                    setData(data.data);
                    setCurrentPage(data.currentPage);
                    setTotalPages(data.totalPages);
                } else {
                    console.error("Error fetching data:", data.error);
                }
            })
            .catch((error) => {
                console.error("Error fetching users:", error);
            });
    }, []);

    useEffect(() => {
        fetchData(currentPage, searchTerm); // Fetch data when page or searchTerm changes
    }, [fetchData, currentPage, searchTerm]);  // Add searchTerm as dependency

    // State to track the user to be edited
    const [editUser, setEditUser] = useState(null);

    // State to manage the visibility of the Add User form
    const [showAddUserForm, setShowAddUserForm] = useState(false);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content");
        window.location.href = "/sign-in";
        return null;
    }

    // Function to set the user to be edited
    const handleEditUser = (user) => {
        setEditUser(user);
    };

    // Function to update the user data
    const updateUser = (userId, newData) => {
        fetch("https://jubilant-backend.netlysystems.com/updateUser", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                userId: userId,
                updatedUserData: newData,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.message === "User updated successfully") {
                    alert("User updated successfully");
                    setEditUser(null);
                    fetchData(currentPage, searchTerm); // Fetch data for the current page after update
                } else {
                    alert("Failed to update user. Please try again.");
                }
            })
            .catch((error) => {
                console.error("Error updating user:", error);
                alert("Error updating user. Please try again.");
            });
    };

    // Change page handler
    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber); // Update page number and fetch new data
        }
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <a href="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </a>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="Master" onClick={toggleMaster}>
                        Master
                        <i className={`fas fa-chevron-${isMasterOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isMasterOpen && (
                        <>
                            <li><Link to={"/clientMaster"}><i className="fas fa-users icon"></i>Client Master</Link></li>
                            <li><Link to={"/machineMaster"}><i className="fas fa-server icon"></i>Machine Master</Link></li>
                            <li><Link to={"/stateMaster"}><i className="fas fa-globe icon"></i>State Master</Link></li>
                            <li><Link to={"/batteryMaster"}><i className="fas fa-battery icon"></i>Battery Master</Link></li>
                            <li><Link to={"/godownMaster"}><i className="fas fa-store icon"></i>Godown Master</Link></li>
                            <li><Link to={"/companyMaster"}><i className="fas fa-building icon"></i>Company Master</Link></li>
                            <li><Link to={"/vendorMaster"}><i className="fas fa-hdd icon"></i>Vendor Master</Link></li>
                            <li><Link to={"/inventoryMaster"}><i className="fas fa-store icon"></i>Inventory Master</Link></li>
                        </>
                    )}
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li>
                        <a href="/userdashboard" className="active">
                            <i className="fas fa-user icon"></i> Users
                        </a>
                    </li>
                    <li><Link to={'/clientdashboard'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/machinedashboard'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/supervisorAllRequests'}><i className="fas fa-ticket icon"></i>Requests</Link></li>
                    <li><Link to={'/superAdminInspection'}><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/transferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>

                    <li className="divider" data-text="Inventory and Reports">Inventory</li>
                    <li><Link to={"/inventorydashboard"}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/deadStock-SA"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/inspection-report"}><i className="fas fa-street-view icon"></i>Inspection Report</Link></li>
                            <li><Link to={"/inventory-report"}><i className="fas fa-store icon"></i>Inventory Report</Link></li>
                            <li><Link to={"/machine-loan-report"}><i className="fas fa-server icon"></i>Machine Loan Report</Link></li>
                            <li><Link to={"/machine-transfer-report"}><i className="fas fa-truck icon"></i>Machine Transfer Report</Link></li>
                            <li><Link to={"/supervisor-stock-report"}><i className="fas fa-ticket icon"></i>Stock Issue Report</Link></li>
                            <li><Link to={"/machine-stock-report"}><i className="fas fa-server icon"></i>Machine Wise Report</Link></li>
                            <li><Link to={"/supervisor-inventory-report"}><i className="fas fa-archive icon"></i>Supervisor Inventory Report</Link></li>
                            <li><Link to={"/machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                            <li><Link to={"/storekeeper-usage-report"}><i className="fas fa-sitemap icon"></i>Storekeeper Usage Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Super Admin</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/userdashboard">Users</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div className="users-page">
                        <div className="search-bar-container" style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }}>
                            <h1 style={{ color: "white", margin: 2 }}>Users :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by name, email, godwon, or role..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)} // Update the searchTerm state
                            />
                        </div>
                        <button className="add-user-btn" style={{ marginBottom: '10px', borderRadius: '10px' }} onClick={() => setShowAddUserForm(true)}>
                            <i className="fas fa-plus-circle"></i> Add User
                        </button>
                        {showAddUserForm && <AddUserForm />}
                        {editUser && <UpdateUser user={editUser} updateUser={updateUser} />}
                        {data.length === 0 && <p>No users found.</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "80px", backgroundColor: "#007bff", color: "white" }}>S.No</th>
                                    <th style={{ width: "200px", backgroundColor: "#007bff", color: "white" }}>Name</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Role</th>
                                    <th style={{ width: "250px", backgroundColor: "#007bff", color: "white" }}>Godown</th>
                                    <th style={{ width: "350px", backgroundColor: "#007bff", color: "white" }}>Email</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>City</th>
                                    <th style={{ width: "170px", backgroundColor: "#007bff", color: "white" }}>State</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Phone</th>
                                    <th style={{ width: "120px", backgroundColor: "#007bff", color: "white" }}>Status</th>
                                    <th style={{ width: "60px", backgroundColor: "#007bff", color: "white" }}>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((user, index) => (
                                    <tr style={{ backgroundColor: "#F1F0F6", borderBottom: "0px solid black" }} key={index}>
                                        <td style={{ width: "80px", border: "1px solid black" }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                        <td style={{ width: "200px", border: "1px solid black" }}>{user.fname}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.userType}</td>
                                        <td style={{ width: "250px", border: "1px solid black" }}>{user.godownName || '-'}</td>
                                        <td style={{ width: "350px", border: "1px solid black" }}>{user.email}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{user.city}</td>
                                        <td style={{ width: "170px", border: "1px solid black" }}>{user.state}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.phone}</td>
                                        <td style={{ width: "120px", border: "1px solid black" }}>{user.status}</td>
                                        <td style={{ width: "60px", border: "1px solid black" }}>
                                            <i onClick={() => handleEditUser(user)} className="fas fa-pencil-square"></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous Page
                            </button>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages || data.length < usersPerPage}
                            >
                                Next Page
                            </button>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
};

export default UserDashboard;